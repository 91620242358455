"use client";

import { TEN_X } from "@/data";
import { Badge } from "./PricingBody";

const EngineeringProductivity = () => {
  return (
    <div className="md:w-auto mx-5 md:mx-[158px] md:py-[84px] px-0 text-white">
      {/* <button className="flex items-center gap-1 px-3 py-1 text-xs md:text-base bg-[rgba(255,255,255,0.04)] border border-rt-purple-p3  text-rt-purple-p3 rounded-full hover:bg-gray-700 transition">
        <span>What you get?</span>
        <img src={RT_ICON_RIGHT_ARROW} alt="Right Arrow" className="w-4 h-4" />
      </button> */}
      <Badge
        type="What you get?"
        arrow={true}
        className="h-9 text-[16px] px-5 py-0.5 mb-6"
      />
      <h2 className="mt-6 text-2xl font-semibold w-fit">
        <span className="font-semibold text-[24px] md:text-[32px] gradient-text">
          10x increase in <br className="md:hidden" /> engineering{" "}
          <br className="md:hidden" /> productivity:
        </span>
      </h2>
      <p className="text-[24px] md:text-[32px] text-white/80 font-normal w-fit mb-6 md:mb-[56px]">
        build test boilerplates in <br className="md:hidden" /> minutes!
      </p>
      <div className="flex items-center justify-between md:max-w-[1200px] xl:max-w-full">
        <img
          src={TEN_X}
          className="md:w-[282px] md:h-[222px] hidden md:block"
          alt="10x image"
        />
        <p className="text-rt-purple-p6 text-[18px] md:text-[32px] leading-[130%] font-inter font-light md:max-w-[824px] xl:max-w-full md:ml-10">
          AI agents in autonomous API testing free developers from manual
          testing, enabling them to focus on building features. They quickly
          identify issues, streamline debugging, and ensure seamless integration
          into CI/CD pipelines.
        </p>
      </div>
    </div>
  );
};

export default EngineeringProductivity;
