"use client";

import { RT_INTEGRATION_BG, RT_INTEGRATION_MOBILE_BG } from "@/data";

const Integration = () => {
  return (
    <div className="w-full" data-section="Integration">
      <div className="bg-rt-purple-b1 rounded-[20px] mx-5 h-full md:mx-[158px] md:h-[561px]">
        <div className="relative md:h-[561px] flex flex-col md:flex-row items-center justify-between md:p-10 text-white overflow-hidden gap-9 md:gap-0 py-10">
          <div className="max-w-lg md:top-3 md:hidden z-10 relative px-5">
            <h2 className="text-[28px] font-bold bg-gradient-to-r from-rt-purple-p4 to-rt-purple-p5 bg-clip-text text-transparent">
              Integrates <br className="md:hidden" /> seamlessly
            </h2>
            <p className="mt-4 text-rt-purple-p6 text-2xl leading-9 font-normal font-inter">
              AI-powered platforms like Loveable.dev, Replit, and Devin
              revolutionize development with automation, intelligent workflows,
              and streamlined delivery pipelines.
            </p>
          </div>
          <img
            src={RT_INTEGRATION_MOBILE_BG}
            alt="Integration Background"
            className="block md:hidden w-full px-5 h-full object-cover"
          />

          {/* Desktop Image */}
          <img
            src={RT_INTEGRATION_BG}
            alt="Integration Background"
            className="hidden md:block absolute inset-0 w-auto mx-auto mr-0 h-full object-contain"
          />
          <div className="max-w-lg md:top-3 md:block hidden z-10 relative px-5">
            <h2 className="text-[28px] font-bold bg-gradient-to-r from-rt-purple-p4 to-rt-purple-p5 bg-clip-text text-transparent">
              Integrates <br className="md:hidden" /> seamlessly
            </h2>
            <p className="mt-4 text-rt-purple-p6 text-2xl leading-9 font-normal font-inter">
              AI-powered platforms like Loveable.dev, Replit, and Devin
              revolutionize development with automation, intelligent workflows,
              and streamlined delivery pipelines.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integration;
