"use client";
import { RT_ICON_RIGHT_ARROW, RT_ICON_RIGHT_ARROW_GREEN } from "@/data";
import {
  useMotionValueEvent,
  useScroll,
  useTransform,
  motion,
} from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

interface TimelineEntry {
  title: string;
  button: {
    text: string;
    link: string;
  };
  content: React.ReactNode;
}

export const Timeline = ({ data }: { data: TimelineEntry[] }) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setHeight(rect.height);
    }
  }, [ref]);

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 10%", "end 50%"],
  });

  const heightTransform = useTransform(scrollYProgress, [0, 0.8], [0, height]);
  const opacityTransform = useTransform(scrollYProgress, [0, 0.1], [0, 1]);

  return (
    <div
      className=" dark:bg-neutral-950 font-sans md:mx-[158px]"
      ref={containerRef}
    >
      <div ref={ref} className="relative mx-auto pb-20">
        {data.map((item, index) => (
          <div
            key={index}
            className="flex justify-between pt-10 md:pt-40 md:gap-10"
          >
            <div className="h-10 z-10 sticky top-40 left-6 md:left-1/2 md:-translate-x-1/2 w-10 rounded-full bg-transparent dark:bg-black flex items-center justify-center">
              <div className="h-4 w-4 rounded-full bg-white dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 p-2" />
            </div>
            <div className="flex flex-col md:flex-row z-40 items-center top-40 self-start max-w-sm lg:max-w-lg md:w-full">
              {/* <div className="h-10 absolute left-3 md:left-1/2 md:-translate-x-1/2 w-10 rounded-full bg-transparent dark:bg-black flex items-center justify-center">
                <div className="h-4 w-4 rounded-full bg-white dark:bg-neutral-800 border border-neutral-300 dark:border-neutral-700 p-2" />
              </div> */}
              <div className="flex flex-col justify-start items-start gap-7">
                <button className="hidden md:flex gap-1 items-center cursor-default text-rt-green-t2 border border-rt-green-t2 rounded-full py-[2px] px-4 w-fit">
                  {item.button.text}
                  <img
                    src={RT_ICON_RIGHT_ARROW_GREEN}
                    alt="Right Arrow"
                    className="w-4 h-4"
                  />
                </button>
                <h3 className="hidden md:block md:text-[28px] md:pl-20 md:text-5xl font-normal lg:pl-0 lg:text-white/80 dark:text-neutral-500 ">
                  {item.title}
                </h3>
              </div>
            </div>

            <div className="relative lg:pl-[72px] md:pl-4 pl-[60px] mr-5 md:ml-0 md:w-1/2">
              <button className="md:hidden flex gap-1 items-center mt-3 text-rt-green-t2 border border-rt-green-t2 rounded-full py-[2px] pl-[14px] pr-[10px] w-fit mb-7">
                {item.button.text}
                <img
                  src={RT_ICON_RIGHT_ARROW_GREEN}
                  alt="Right Arrow"
                  className="w-4 h-4"
                />
              </button>
              <h3 className="md:hidden block text-[18px] mb-6 text-left font-bold text-white/80 dark:text-neutral-500">
                {item.title}
              </h3>
              {item.content}
            </div>
          </div>
        ))}
        <div
          style={{
            height: height - 100 + "px",
          }}
          className="absolute left-8 md:left-1/2 md:-translate-x-1/2 top-0 overflow-hidden w-[2px] bg-[linear-gradient(to_bottom,var(--tw-gradient-stops))] from-transparent from-[0%] via-neutral-200 dark:via-neutral-700 to-transparent to-[99%]  [mask-image:linear-gradient(to_bottom,transparent_0%,black_10%,black_90%,transparent_100%)]"
        >
          <motion.div
            style={{
              height: heightTransform,
              opacity: opacityTransform,
            }}
            className="absolute inset-x-0 top-0  w-[2px] bg-gradient-to-t from-purple-500 via-blue-500 to-transparent from-[0%] via-[10%] rounded-full"
          />
        </div>
      </div>
    </div>
  );
};
