import Image from "next/image";
import React from "react";
import { Timeline } from "@/components/ui/timeline_v2";
import { RT_AUTO_MANUAL, RT_INTEGRATE_TOOLS, UPLOAD_ARTIFACT } from "@/data";

export function JourneyTimeline() {
  const data = [
    {
      title: "Upload or integrate API artifact",
      button: {
        text: "Get Started",
        link: "/",
      },
      content: (
        <div>
          <p className="text-rt-purple-p6 dark:text-neutral-200 text-xs md:text-[16px] font-inter leading-[160%] font-normal mb-8">
            Connect your preferred API documentation platform or upload a cURL
            to get started. With just a one-time setup, it seamlessly tracks and
            manages your artifacts, ensuring effortless updates and consistent
            testing workflows
          </p>
          <Image
            src={UPLOAD_ARTIFACT}
            alt="startup template"
            width={500}
            height={500}
            className="rounded-lg object-cover w-full"
          />
        </div>
      ),
    },
    {
      title: "Choose either autonomous or manual execution",
      button: {
        text: "Execution",
        link: "/",
      },
      content: (
        <div>
          <p className="text-rt-purple-p6 dark:text-neutral-200 text-xs md:text-[16px] font-inter leading-[160%] font-normal mb-8">
            Empowers users with AI agents for autonomous API testing and
            AI-enhanced UI testing. Effortlessly create test cases, generate
            code, and execute tests all within minutes, eliminating unnecessary
            intermediate steps
          </p>
          <Image
            src={RT_AUTO_MANUAL}
            alt="hero template"
            width={500}
            height={500}
            className="rounded-lg object-cover w-full"
          />
        </div>
      ),
    },
    {
      title: "Integrate your favourite tools",
      button: {
        text: "Integration",
        link: "/",
      },
      content: (
        <div>
          <p className="text-rt-purple-p6 dark:text-neutral-200 text-xs md:text-[16px] font-inter leading-[160%] font-normal mb-4">
            Integrates effortlessly with tools like Postman, Swagger, Slack,
            Jira, Azure, and CI/CD pipelines. Enhance your workflows with
            AI-driven automation while staying connected to the platforms you
            trust for seamless collaboration and efficiency
          </p>
          <Image
            src={RT_INTEGRATE_TOOLS}
            alt="hero template"
            width={500}
            height={500}
            className="rounded-lg object-cover w-full"
          />
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Timeline data={data} />
    </div>
  );
}
