"use client";

import { cn } from "@/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { useState } from "react";
import Picture from "../ratl/utility/Picture";
import * as LucideIcons from "lucide-react";

export const HoverEffect = ({
  items,
  className,
}: {
  items: {
    title: string;
    subtitle: string;
    icon: string;
    slug: string;
    excerpt: string;
  }[];
  className?: string;
}) => {
  let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-6 lg:grid-cols-3 pb-10",
        className
      )}
    >
      {items.map((item, idx) => {
        const IconComponent =
          (LucideIcons as any)[item.icon] || LucideIcons.HelpCircle;
        return (
          <Link
            href={`/use-cases/${item.slug}`}
            key={item.slug}
            className="relative group block p-0 h-full w-full"
            onMouseEnter={() => setHoveredIndex(idx)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <AnimatePresence>
              {hoveredIndex === idx && (
                <motion.span
                  className="absolute inset-0 h-full w-full bg-rt-purple-p1/70 dark:bg-slate-800/[0.8] block rounded-2xl"
                  layoutId="hoverBackground"
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 0.7,
                    transition: { duration: 0.15 },
                  }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 0.15, delay: 0.2 },
                  }}
                />
              )}
            </AnimatePresence>
            <Card>
              <IconComponent className="md:w-16 md:h-16 h-10 w-10 stroke-[1.5px] text-white" />
              <CardTitle className="text-card-title text-[28px] mt-10">
                {item.title}
              </CardTitle>
              <p className="text-[28px] text-rt-grey-p1 mt-2">
                {item.subtitle}
              </p>
              <CardDescription>{item.excerpt}</CardDescription>
            </Card>
          </Link>
        );
      })}
    </div>
  );
};

export const Card = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "rounded-2xl h-full w-full p-0 overflow-hidden [box-shadow:2px_4px_16px_1px_rgba(159,_147,_227,_0.06)_inset] bg-card-bg-b2/30 border border-white/10 dark:border-white/[0.2] group-hover:border-slate-700 relative z-20",
        className
      )}
    >
      <div className="relative z-50">
        <div className="p-10">{children}</div>
      </div>
    </div>
  );
};

export const CardTitle = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <h4
      className={cn(
        "text-rt-green-p1/70 font-medium text-xl tracking-wide mt-4",
        className
      )}
    >
      {children}
    </h4>
  );
};

export const CardDescription = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <p
      className={cn(
        "mt-8 text-zinc-400 tracking-wide leading-relaxed text-sm",
        className
      )}
    >
      {children}
    </p>
  );
};

export const BlogIcon = ({}: {}) => {
  const randomNum = getRandomNumber();
  const imgPath = "/ratl/ratl-mascot-" + randomNum + ".png";
  return (
    <div>
      <Picture size={[56]} src={imgPath}></Picture>
    </div>
  );
};

function getRandomNumber(): 1 | 2 | 3 {
  const numbers: [1, 2, 3] = [1, 2, 3];
  const randomIndex = Math.floor(Math.random() * numbers.length);
  return numbers[randomIndex];
}
