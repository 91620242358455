"use client";

import { RT_ICON_RIGHT_ARROW, RT_INTEGRATION_BG, RT_MASCOT_V1 } from "@/data";
import { JourneyTimeline } from "./JourneyTimeline";
import bg from "public/ratl/bg/BG_1.png";
import Image from "next/image";
import { Badge } from "./PricingBody";

const SuperchargeTesting = () => {
  return (
    <div
      className="relative flex flex-col items-center justify-center md:text-center text-white mx-5"
      data-section="How it works"
    >
      {/* <div className="absolute inset-0 bg-gradient-radial from-[#9D89FF]/10 to-[#010314]"></div> */}
      <div className="h-[240px] md:h-[376px]">
        <Image className="h-[240px] md:h-[376px]" src={bg} alt="bg1" />
      </div>
      {/* <button className="flex items-center justify-center px-4 py-1 mb-6 text-sm md:text-base text-rt-purple-p3 bg-gray-800/50 border border-gray-700 rounded-full hover:bg-gray-700 transition">
        {"How it Works?"}
        <img src={RT_ICON_RIGHT_ARROW} alt="Right Arrow" className="w-4 h-4" />
      </button> */}

      <Badge
        type="How it Works?"
        arrow={true}
        className="h-9 text-[16px] px-5 py-0.5 mb-6"
      />
      <h2 className="text-[28px] md:text-[32px] font-bold bg-gradient-to-r from-rt-purple-p4 to-rt-purple-p5 bg-clip-text text-transparent">
        Supercharge Testing Journey
      </h2>
      <p className="mt-5 md:mt-7 font-inter text-rt-purple-p6 text-[18px] md:text-[24px]">
        New to AI or a pro? ratl Studio offers unlimited workspaces with
        AI-assisted and <br className="hidden md:block" /> autonomous testing by
        powerful AI agents. Explore now!
      </p>
    </div>
  );
};

export default SuperchargeTesting;
