"use client";
import React, { useEffect, useState } from "react";
import { HoverEffect } from "../ui/card-hover-effect-v2";
import { Post } from "@/lib/posts";

const UseCases = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetch("/api/posts")
      .then((res) => res.json())
      .then((data) => {
        const formattedBlogs = data.slice(0, 3).map((post: Post) => ({
          title: post.metadata.title,
          subtitle: post.metadata.subtitle,
          slug: post.slug,
          icon: post.metadata.icon,
          excerpt: post.metadata.excerpt,
        }));
        setBlogs(formattedBlogs);
      });
  }, []);

  return (
    <div className="w-full text-card-title px-5 md:px-[7.5rem]">
      <div
        className="relative flex flex-col items-center justify-center text-center text-white mx-5"
        data-section="How it works"
      >
        <h2 className="text-[28px] md:text-[32px] font-bold bg-gradient-to-r from-rt-purple-p4 to-rt-purple-p5 bg-clip-text text-transparent">
          See how ratl fits in
        </h2>
        <p className="font-inter text-rt-purple-p6 text-[18px] md:text-[24px]">
          Choose your use case and discover how ratl.ai can help your business.
        </p>
      </div>
      <HoverEffect className="pt-7" items={blogs} />
    </div>
  );
};

export default UseCases;
