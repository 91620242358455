import { API_BASE_URL, RT_MASCOT_V1, UI_BASE_URL } from "@/data";
import { useRef, useState } from "react";
import CustomButton from "../ratl/utility/CustonButton";
import { useFloat } from "@/lib";
import Image from "next/image";
import { Paperclip } from "lucide-react";

const StartTesting = () => {
  const { float, handleFloat } = useFloat([false, false]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [isValidCurl, setIsValidCurl] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  const handleSampleCurlClick = () => {
    if (textareaRef.current) {
      textareaRef.current.value =
        "curl -X 'GET' 'https://reqres.in/api/users' -H 'accept: application/json'";
      setIsInputEmpty(false);
      setIsValidCurl(true);
    }
  };

  const preHandleClickMobile = () => {
    setIsMobile(true);
    if (isMobile) {
      const inputValue =
        "curl -X 'GET' 'https://reqres.in/api/users' -H 'accept: application/json'";
      handleStartTestingClick(inputValue);
    }
  };

  const preHandleClick = () => {
    if (!isInputEmpty && isValidCurl && textareaRef.current && !isMobile) {
      const inputValue = textareaRef.current.value.trim();
      handleStartTestingClick(inputValue);
    }
  };

  const handleStartTestingClick = async (input: string) => {
    try {
      const response = await fetch(
        `/api/guestSesion`,
        // `${API_BASE_URL}/tempest/api/v2/public/guest`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: input }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to establish guest session");
      }

      const result = await response.json();
      const sessionId = result.guestSessionId;

      if (sessionId) {
        window.open(`${UI_BASE_URL}?id=${sessionId}`, "_blank");
      } else {
        console.log("Failed to retrieve session ID");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleInputChange = async () => {
    if (textareaRef.current) {
      const inputValue = textareaRef.current.value.trim();

      // Restrict excessively large inputs
      if (inputValue.length > 5000) {
        alert("Input is too large. Please provide a smaller cURL command.");
        setIsValidCurl(false);
        return;
      }

      setIsInputEmpty(inputValue === "");

      // Call the API to validate the cURL command
      try {
        const response = await fetch("/api/validate-curl", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ curlCommand: inputValue }),
        });

        if (!response.ok) {
          throw new Error("Invalid cURL");
        }

        const result = await response.json();
        setIsValidCurl(result.valid);
      } catch (error) {
        console.error("Validation error:", error);
        setIsValidCurl(false);
      }
    }
  };

  return (
    <>
      {/* Mobile View */}
      <div className="relative md:top-1 lg:hidden w-fit">
        <CustomButton
          text={"Try Sample cURL"}
          animationRequired={false}
          bgType={"solid"}
          onMouseEnter={() => handleFloat(true, 1)}
          onMouseLeave={() => handleFloat(false, 1)}
          controller={float[1]}
          image={RT_MASCOT_V1}
          onClick={preHandleClickMobile}
          btnSize="small"
          isStudioBtn={false}
          showMobile={true}
          className="w-fit"
        />
      </div>

      {/* Desktop View */}
      <div className="bg-[#040218cc]/80 w-full rounded-2xl lg:flex lg:gap-[22.5px] flex-col px-8 pt-8 pb-6 border-solid shadow-[0px_0px_12px_2px_rgba(102,_57,_186,_0.30)] border-rt-purple-border border-rt-regular relative hidden">
        <textarea
          id="typewriter-input"
          rows={4}
          ref={textareaRef}
          onChange={handleInputChange}
          className="w-full bg-transparent border-none text-gray-300 placeholder-text-rt-grey-t4 text-sm outline-none mb-4 mx-auto resize-none overflow-y-auto"
          placeholder="Enter your cURL command here"
        />
        <div className="flex flex-row justify-between items-end gap-2 font-inter ">
          <div className="flex flex-wrap justify-start gap-3 text-sm ">
            <button
              onClick={handleSampleCurlClick}
              className=" text-white border-rt-purple-p6 border-[1px] px-3 py-1.5 rounded-[20px] text-xs flex gap-2 items-center hover:bg-gray-600"
            >
              <Image src={RT_MASCOT_V1} alt="" width={24} height={18} /> Try
              Sample cURL
            </button>
            <button
              disabled
              className=" text-rt-grey-t4 border-rt-purple-p6/80 border-[1px] px-3 py-1.5 rounded-[20px] text-xs flex gap-2 items-center cursor-not-allowed relative group"
            >
              <Image
                src={RT_MASCOT_V1}
                alt=""
                className="grayscale-[80%]"
                width={24}
                height={18}
              />{" "}
              Try Sample API Collection
              <span className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-gray-500 text-xs rounded-md px-3 py-1.5 opacity-0 group-hover:opacity-100 transition-opacity">
                Upgrade
              </span>
            </button>
            <button
              disabled
              className=" text-rt-grey-t4 px-4 py-1 rounded-[20px] border-rt-purple-p6/80 border-[1px] text-xs flex gap-2 items-center cursor-not-allowed relative group"
            >
              <Paperclip size={20} /> Upload
              <span className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-gray-800 text-gray-500 text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity">
                Upgrade
              </span>
            </button>
          </div>

          <div className="flex flex-wrap gap-3">
            <div className="flex items-center justify-between">
              {/* <button
                onClick={preHandleClick}
                disabled={isInputEmpty || !isValidCurl}
                className={`relative px-4 py-2 text-rt-grey-t5 bg-rt-purple-p6 opacity-60 rounded-[22px] text-sm font-semibold flex items-center transition ${
                  isInputEmpty || !isValidCurl
                    ? "bg-gray-800 cursor-not-allowed"
                    : "bg-white opacity-80 hover:bg-white hover:opacity-100"
                } group`}
              >
                Start Testing
              </button> */}
              <CustomButton
                text={" Start Testing"}
                animationRequired={false}
                bgType={"solid"}
                onMouseEnter={() => handleFloat(true, 1)}
                onMouseLeave={() => handleFloat(false, 1)}
                controller={float[1]}
                onClick={preHandleClick}
                btnSize="small"
                isStudioBtn={false}
                disabled={isInputEmpty || !isValidCurl}
                className="font-semibold"
              />
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartTesting;
