"use client";

import {
  RT_ICON_RIGHT_ARROW,
  RT_INTEGRATION_BG,
  RT_INTRO_VIDEO_CDN,
  RT_INTRO_VIDEO_YT,
  RT_MASCOT_V1,
} from "@/data";
import { useEffect, useState } from "react";
import YouTubeEmbed from "../ratl/utility/YouTubeEmbed";
import VideoBox from "../widgets/VideoBox";
import { Badge } from "./PricingBody";

const DemoVideo = () => {
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const checkVideoAvailability = async () => {
      try {
        const response = await fetch(RT_INTRO_VIDEO_CDN, { method: "HEAD" });
        if (!response.ok) {
          setIsError(true);
        }
      } catch (error) {
        setIsError(true);
      }
    };

    checkVideoAvailability();
  }, [RT_INTRO_VIDEO_CDN]);
  return (
    <div className="flex flex-col items-start justify-center gap-1 text-white mx-5 md:mx-[158px]">
      {/* Badge / Small Button */}
      {/* <button className="flex items-center justify-center px-4 py-1 mb-6 text-xs md:text-base text-rt-purple-p3 bg-gray-800/50 border border-gray-700 rounded-full hover:bg-gray-700 transition">
        {"Introducing AI Agents for Testing"}
        <img src={RT_ICON_RIGHT_ARROW} alt="Right Arrow" className="w-4 h-4" />
      </button> */}

      <Badge
        type="Introducing AI Agents for Testing"
        arrow={true}
        className="h-9 text-[16px] px-5 py-0.5 mb-6"
      />

      {/* Heading & Subheading */}
      <div className="mb-0">
        <h2 className="text-2xl md:text-[32px] font-semibold leading-[130%] bg-gradient-to-r from-rt-purple-p4 to-rt-purple-p5 bg-clip-text text-transparent">
          Accelerate high-quality software delivery
        </h2>
        <p className="text-white opacity-80 mb-0 md:mb-16 text-2xl leading-[130%] font-normal md:text-[32px]">
          Here is Quick Demo
        </p>
      </div>

      <div className="w-full rounded-[20px] md:p-5 mt-4 md:mt-0 border border-white/30 bg-[#282828]/30">
        {!isError ? (
          <video
            className="w-full h-full object-cover rounded-[20px]"
            controls
            controlsList="nodownload"
            poster="/ratl/ratl-video-cover.webp"
          >
            <source src={RT_INTRO_VIDEO_CDN} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className="w-full">
            <YouTubeEmbed videoId={RT_INTRO_VIDEO_YT} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DemoVideo;
