"use client";

import {
  RT_API_AGENTS,
  RT_INTEGRATION_BG,
  RT_REDEFINE_SDLC_VID,
  RT_REDEFINE_SDLC_VID_MOBILE,
  RT_API_AGENTS_MOBILE,
  RT_UI_AGENTS,
  RT_UI_TESTING_AGENTS,
  RT_UI_TESTING_AGENTS_MOBILE,
} from "@/data";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });
import { Badge } from "./PricingBody";
import { cn } from "@/lib/utils";
import { uiDesktop } from "@/data/lottie-animation";
import { FeatureBlockCard } from "../ui/feature-block-card";
import { useState } from "react";

const MeetAgents = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isUiHovered, setIsUiHovered] = useState(false);
  return (
    <div className="flex flex-col  md:mx-[158px] text-white mx-5">
      {/* Section Header */}
      <section data-section="Agents">
        <div className="text-start max-w-2xl pb-12">
          <h2 className="text-2xl md:text-[32px] leading-[130%] font-semibold gradient-text">
            Meet the ratl.ai agents
          </h2>
          <p className="mt-1 text-rt-purple-p6 text-[18px] md:text-2xl md:w-[972px] font-light font-inter">
            Autonomously generate and execute API tests, including functional,
            integration, and load testing, while seamlessly integrating with
            CI/CD pipelines.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="rounded-[20px] md:p-[33px] flex flex-col justify-between bg-sdlc-bg"
          >
            {isHovered ? (
              <div className="flex flex-shrink-0 w-full justify-center items-center">
                <FeatureBlockCard />
              </div>
            ) : (
              <div className="w-full flex justify-center items-center h-fit">
                <img
                  src={RT_API_AGENTS}
                  alt="API Testing"
                  className="object-contain mt-4 md:mt-0 md:h-[18rem] rounded-[20px]"
                />
              </div>
            )}
            <div className="flex flex-col justify-start items-start md:justify-center md:items-center text-white mx-5 md:mx-0 mb-4 md:mb-0 gap-2">
              <Badge type="LIVE" className="leading-[12px] tracking-[0.96px]" />
              <h3 className="mt-0 md:mt-0 text-[18px] md:text-[32px] font-semibold text-white">
                ratl API testing agents
              </h3>
            </div>
          </div>

          <div className="rounded-[20px] md:p-[33px] flex flex-col bg-sdlc-bg">
            <div
              onMouseEnter={() => setIsUiHovered(true)}
              onMouseLeave={() => setIsUiHovered(false)}
              className="rounded-[20px] flex flex-col bg-sdlc-bg"
            >
              {isUiHovered ? (
                <div className="w-full md:h-[18rem] flex justify-center items-center h-fit">
                  <Lottie
                    animationData={uiDesktop}
                    className=" h-[18rem]"
                    loop={true}
                  />
                </div>
              ) : (
                <div className="w-full md:h-[18rem] flex justify-center items-center">
                  <img
                    src={RT_UI_TESTING_AGENTS_MOBILE}
                    alt="UI Testing"
                    className="w-full object-contain md:hidden my-8 rounded-[20px]"
                  />
                  <img
                    src={RT_UI_TESTING_AGENTS}
                    alt="UI Testing"
                    className="w-full md:h-[18rem] object-contain hidden md:block scale-[0.902] rounded-[20px]"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col justify-start items-start md:justify-center md:items-center  text-white mx-5 md:mx-0 mb-4 md:mb-0 gap-2">
              <Badge
                type="UPCOMING"
                className="leading-[12px] tracking-[0.96px]"
              />
              <h3 className="mt-0 md:mt-0 text-[18px] md:text-[32px] font-semibold text-white">
                ratl UI testing agents
              </h3>
            </div>
          </div>

          {/* <div className="relative rounded-[20px] md:pt-6 overflow-hidden">
            <img
              src={RT_UI_TESTING_AGENTS}
              alt="UI Testing"
              className="w-full h-full object-cover rounded-[20px]"
            />
            <div className="absolute bottom-6 left-6 right-6 flex flex-col justify-center items-center text-white p-5 md:px-14 md:pb-16 md:pt-10">
              <Badge
                type="UPCOMING"
                className="mb-4 md:mb-2 leading-[12px] tracking-[0.96px]"
              />
              <h3 className="mt-0 md:mt-0 text-[18px] md:text-[32px] font-semibold text-white">
                ratl UI testing agents
              </h3>
            </div>
          </div> */}
        </div>

        <div className="relative bg-sdlc-bg mt-6 rounded-[20px] w-full h-fit">
          <div className="flex flex-col md:flex-row items-center h-fit justify-between p-4 md:p-8 pb-0 md:pl-14 text-white">
            <div className="w-full md:max-w-[342px] ">
              {/* <Badge
                type="VISUALISE INFO"
                className="mb-4 leading-[12px] tracking-[0.96px]"
              /> */}
              <h2 className="text-[18px] md:text-[32px] md:leading-[41.6px] tracking-[-0.8%] font-semibold text-rt-grey-t2 mt-2 md:mt-0">
                Redefining <br /> the SDLC
              </h2>
              <p className="text-white opacity-80 md:leading-[41.6px] tracking-[-0.8%] text-[18px] md:text-[32px] font-normal">
                Embracing AI–Native <br /> Practices
              </p>
            </div>

            {/* Right Image Section */}
            <div className="md:w-[690px] bg-sdlc-bg w-[335px] md:h-[490px] h-fit flex items-center justify-center">
              <video
                autoPlay
                loop
                muted
                playsInline
                src={RT_REDEFINE_SDLC_VID_MOBILE}
                // alt="SDLC Diagram"
                className="w-full object-cover rounded-[20px] md:hidden"
              />
              {/* <img
                src={RT_REDEFINE_SDLC_VID_MOBILE}
                alt="SDLC Diagram"
                className="w-full object-cover py-4 pb-5 md:hidden"
              /> */}
              <video
                autoPlay
                loop
                muted
                playsInline
                src={RT_REDEFINE_SDLC_VID}
                className="object-cover rounded-[20px] md:w-full w-[335px] md:h-[490px] h-fit hidden md:block scale-[1.1] origin-center"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MeetAgents;
