/* eslint-disable @next/next/no-img-element */
import { v4 as uuidv4 } from "uuid";
import { useFloat } from "@/lib";
import {
  heroData,
  RT_HERO_BANNER,
  RT_HERO_BANNER_MOBILE,
  RT_HERO_TITLE_L1,
  RT_STUDIO_RIL,
} from "@/data";

import { useEffect } from "react";
import GlowingIconBg from "../ratl/display/GlowingIconBg";
import CustomButton from "../ratl/utility/CustonButton";

import Picture from "../ratl/utility/Picture";
import StartTesting from "./StartTesting";
import Image from "next/image";
import { motion } from "framer-motion";

const Hero = () => {
  const { float, handleFloat } = useFloat([false, false]);
  useEffect(() => {
    const basePhrase = "Enter";
    const dynamicPhrases = [
      "cURL command",
      "Web URL",
      "Postman Collection URL",
    ];
    let i = 0;
    let j = 0;
    let currentPhrase = "";
    let isDeleting = false;
    const input = document.getElementById("typewriter-input");

    function type() {
      if (input) {
        if (!isDeleting && j <= dynamicPhrases[i].length) {
          currentPhrase = basePhrase + " " + dynamicPhrases[i].substring(0, j);
          input.setAttribute("placeholder", currentPhrase);
          j++;

          if (j === dynamicPhrases[i].length + 1) {
            setTimeout(() => {
              isDeleting = true;
              type();
            }, 1000); // Pause after completing the phrase
            return;
          }
        }

        if (isDeleting && j >= 0) {
          currentPhrase = basePhrase + " " + dynamicPhrases[i].substring(0, j);
          input.setAttribute("placeholder", currentPhrase);
          j--;
        }

        if (isDeleting && j === 0) {
          isDeleting = false;
          i++;
        }

        if (i === dynamicPhrases.length) {
          i = 0;
        }

        setTimeout(type, isDeleting ? 50 : 100);
      }
    }

    type();
  }, []);
  return (
    <section id="hero-home-page" className="w-full">
      <div className="relative mx-0 md:px-[158px] h-fit md:max-w-[1200px] xl:max-w-full">
        <div className="absolute inset-0 z-[-1] hidden md:block h-[1132px] w-screen">
          <Image
            src={RT_HERO_BANNER}
            alt="Hero Background"
            fill
            priority
            className="object-cover"
          />
        </div>
        <div className="absolute inset-0 z-[-1] block md:hidden">
          <Image
            src={RT_HERO_BANNER_MOBILE}
            alt="Hero Background mobile"
            fill
            priority
            className="absolute transition ease-in duration-200 -z-30 object-cover"
          />
        </div>
        {/* <Picture
          className="absolute hero-mascot top-28 w-1/4 h-auto right-0 xl:right-0"
          size={[500, 326]}
          src="/ratl/ratl-mascot-spaceship.png"
          alt={"Ratl mascot flying in a spaceship"}
        /> */}

        {/* left gradient line */}
        {/* <div className="relative">
            <Picture
              size={[437, 637]}
              src="/home-campaign/lines-hero.svg"
              className=""
              alt={"Graphical lines"}
            />

            <div className="mx-auto my-3">
              <span className="relative z-[11]">
                <Picture
                  size={[24]}
                  src={"/ratl/ratl-mascot-2.png"}
                  alt={"ratl mascot section for text area"}
                />
              </span>
            </div>
          </div> */}

        {/* hero body */}
        <div className="relative pt-20 md:pt-56 mx-5 md:mx-0 flex flex-col gap-[120px] md:gap-[0px]">
          <div className="flex flex-col gap-6">
            <div className="max-w-[945px]">
              <h1 className="md:mt-0 mt-[112px] relative z-2 md:mb-5 mb-0 rt-font-hero-title">
                {RT_HERO_TITLE_L1()}
              </h1>
            </div>

            <div className="w-full h-fit">
              <StartTesting />
            </div>
          </div>

          <div className="md:my-11 my-0 lg:my-11">
            <p className="rt-para text-rt-purple-p3 text-[18px]">
              Trusted by the world's leading organizations
            </p>

            <div className="flex flex-wrap gap-4 md:gap-4 lg:gap-10 items-center mt-4">
              {heroData.collabs.map((logo) => (
                <Image
                  key={logo.src}
                  src={logo.src}
                  alt={logo.alt}
                  width={logo.width}
                  height={logo.height}
                  className="max-w-[120px] sm:max-w-[150px] md:max-w-[200px] object-contain mt-3 md:mt-0" // Adjust sizes based on screen
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
