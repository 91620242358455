"use client";
import Hero from "@/components/sections/Hero";
import HeroBanner from "@/components/HeroBanner";
import Integration from "@/components/sections/Integration";
import SimpleSteps from "@/components/sections/SimpleSteps";
import DemoVideo from "@/components/sections/DemoVideo";
import MeetAgents from "@/components/sections/MeetAgents";
import EngineeringProductivity from "@/components/sections/EngineeringProductivity";
import SuperchargeTesting from "@/components/sections/SuperchargeTesting";
import { JourneyTimeline } from "@/components/sections/JourneyTimeline";
import UseCases from "@/components/sections/UseCases";

/* eslint-disable @next/next/no-img-element */
export default function Home() {
  return (
    <div className="">
      <section data-section="Hero">
        {/* <HeroBanner /> */}
        <Hero />
        <div className="h-[120px] md:h-[188px]"></div>
        <DemoVideo />
        <div className="h-[120px] md:h-[188px]"></div>
      </section>

      <MeetAgents />
      <div className="h-[120px] md:h-[188px]"></div>
      <EngineeringProductivity />
      <SuperchargeTesting />
      <div className="h-[48px] md:h-[0px]"></div>
      <JourneyTimeline />

      <SimpleSteps />
      <div className="h-[120px] md:h-[188px]"></div>

      <Integration />
      <div className="h-[120px] md:h-[188px]"></div>

      <UseCases />
    </div>
  );
}
