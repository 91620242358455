"use client";
import { animate, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { cn } from "@/lib/utils";
import { GoCopilot } from "react-icons/go";
import {
  RT_AGENT_CASEY,
  RT_AGENT_CHASE,
  RT_AGENT_INGA,
  RT_AGENT_MOB,
  RT_AGENT_WEB,
  RT_API_AGENTS_MOBILE,
  RT_UI_AGENTS,
} from "@/data";

export function FeatureBlockCard() {
  return (
    <CardSkeletonContainer showGradient={true}>
      <Skeleton />
    </CardSkeletonContainer>
  );
}

const Skeleton = () => {
  const scale = [1, 1.1, 1];
  const transform = ["translateY(0px)", "translateY(-4px)", "translateY(0px)"];
  const sequence: [
    string,
    { scale: number[]; transform: string[] },
    { duration: number }
  ][] = [
    [
      ".circle-1",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-2",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-3",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-4",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
    [
      ".circle-5",
      {
        scale,
        transform,
      },
      { duration: 0.8 },
    ],
  ];

  useEffect(() => {
    animate(sequence, {
      // @ts-ignore
      repeat: Infinity,
      repeatDelay: 1,
    });
  }, []);
  return (
    <div className="p-8 overflow-hidden h-full relative bg-transparent flex items-center justify-center">
      {/* <div className="flex flex-row flex-shrink-0 justify-center items-center gap-5">
        <div className="flex flex-col gap-3">
          <Container className="h-[52px] w-[52px] circle-1 mb-10">
            <img
              src={RT_AGENT_WEB}
              alt="Mob"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </Container>
        </div>
        <div className="flex flex-col gap-3">
          <Container className="h-[88px] w-[88px] circle-4">
            <img
              src={RT_AGENT_CASEY}
              alt="Casey"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </Container>
          <div className="flex flex-col justify-center items-center gap-[2px]">
            <p className="text-sm font-semibold">Casey</p>
            <p className="text-xs font-normal">Accessibility</p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Container className="h-[120px] w-[120px] circle-3">
            <img
              src={RT_AGENT_INGA}
              alt="Inga"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </Container>
          <div className="flex flex-col justify-center items-center gap-[2px]">
            <p className="text-sm font-semibold">Inga</p>
            <p className="text-xs font-normal">E2E</p>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <Container className="h-[88px] w-[88px] circle-2">
            <img
              src={RT_AGENT_CHASE}
              alt="Chase"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </Container>
          <div className="flex flex-col justify-center items-center gap-[2px]">
            <p className="text-sm font-semibold">Chase</p>
            <p className="text-xs font-normal">Load</p>
          </div>
        </div>
        <div className="flex flex-col gap-3 mb-10">
          <Container className="h-[52px] w-[52px] circle-5">
            <img
              src={RT_AGENT_MOB}
              alt="Web"
              className="w-full h-full object-cover rounded-[20px]"
            />
          </Container>
        </div>
      </div> */}

      <div className="flex flex-row flex-shrink-0 md:h-[18rem] h-fit justify-center">
        <img
          src={RT_API_AGENTS_MOBILE}
          alt="API Testing"
          className="w-full object-cover rounded-[20px] scale-[0.99]"
        />
      </div>

      <div className="h-40 w-px absolute top-20 m-auto z-40 bg-gradient-to-b from-transparent via-cyan-500 to-transparent animate-move">
        <div className="w-10 h-32 top-1/2 -translate-y-1/2 absolute -left-10">
          <Sparkles />
        </div>
      </div>
    </div>
  );
};

const Sparkles = () => {
  const randomMove = () => Math.random() * 2 - 1;
  const randomOpacity = () => Math.random();
  const random = () => Math.random();
  return (
    <div className="absolute inset-0">
      {[...Array(12)].map((_, i) => (
        <motion.span
          key={`star-${i}`}
          animate={{
            top: `calc(${random() * 100}% + ${randomMove()}px)`,
            left: `calc(${random() * 100}% + ${randomMove()}px)`,
            opacity: randomOpacity(),
            scale: [1, 1.2, 0],
          }}
          transition={{
            duration: random() * 2 + 4,
            repeat: Infinity,
            ease: "linear",
          }}
          style={{
            position: "absolute",
            top: `${random() * 100}%`,
            left: `${random() * 100}%`,
            width: `2px`,
            height: `2px`,
            borderRadius: "50%",
            zIndex: 1,
          }}
          className="inline-block bg-white dark:bg-white"
        ></motion.span>
      ))}
    </div>
  );
};

export const Card = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "max-w-sm w-full mx-auto p-8 rounded-xl border border-[rgba(255,255,255,0.10)] dark:bg-[rgba(40,40,40,0.70)] bg-gray-100 shadow-[2px_4px_16px_0px_rgba(248,248,248,0.06)_inset] group",
        className
      )}
    >
      {children}
    </div>
  );
};

export const CardTitle = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <h3
      className={cn(
        "text-lg font-semibold text-gray-800 dark:text-white py-2",
        className
      )}
    >
      {children}
    </h3>
  );
};

export const CardDescription = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <p
      className={cn(
        "text-sm font-normal text-neutral-600 dark:text-neutral-400 max-w-sm",
        className
      )}
    >
      {children}
    </p>
  );
};

export const CardSkeletonContainer = ({
  className,
  children,
  showGradient = true,
}: {
  className?: string;
  children: React.ReactNode;
  showGradient?: boolean;
}) => {
  return (
    <div
      className={cn(
        "h-[15rem] md:h-[18rem] rounded-xl z-40",
        className,
        showGradient &&
          "bg-sdlc-bg dark:bg-[rgba(40,40,40,0.70)] [mask-image:radial-gradient(80%_80%_at_50%_50%,white_60%,transparent_100%)]"
      )}
    >
      {children}
    </div>
  );
};

const Container = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        `h-16 w-16 rounded-2xl  flex items-center justify-center bg-[rgba(248,248,248,0.01)]
    shadow-[0px_0px_8px_0px_rgba(248,248,248,0.25)_inset,0px_32px_24px_-16px_rgba(0,0,0,0.40)]
    `,
        className
      )}
    >
      {children}
    </div>
  );
};
